/**
 * Transforms address information into a format suitable for direction parameters.
 * Extracts and structures geographic coordinates, country code, town, and a raw address string.
 * @param {object} info - The address information object, typically obtained from geocoding.
 *                        Expected to contain coordinates (coords), country code (code), town name (town),
 *                        country name (countryName), and full address line (addressLine).
 * @returns {object} An object containing structured direction parameters: geographic coordinates (geo),
 *                   country code (code), town name (town), and a raw address string (raw).
 */
export const getDirectionParamsFromAddressInfo = info => ({
    geo: {
        lat: info.coords[0],
        lng: info.coords[1],
    },
    code: info.code,
    town: info.town,
    raw: `${info.countryName}, ${info.addressLine}`,
});
